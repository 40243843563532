.subscribe-form {
	input {
		height: rem-calc(50);
		padding: rem-calc(16 20);

		@media #{$large-up} {
			font-size: rem-calc(14);
		}
	}
	[type=email] {
		background-color: $white;
		border-color: $primary-color;

		&:focus {
			background-color: $white;
			border-color: $primary-color;
		}

		&::placeholder {
			color: $primary-color;
		}
	}
	[type=submit].button {
		text-transform: uppercase;
		letter-spacing: 0.06em;
		padding: rem-calc(16 20);
		width: 100%;
	}


	@media #{$small-only} {
		.input-group,
		.input-group-field,
		.input-group-button { display: block; }

		.input-group-field {
			margin-bottom: $paragraph-margin-bottom;
		}

		.input-group-button {
			width: 100%;

			input[type=submit] {
				width: 100%;
			}
		}
	}
}