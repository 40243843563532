/** RESTAURANT TEMPLATE STYLING **/
.restaurant-content {
	margin: 2rem auto;

	h2 {
		font-size: 1.2rem;
		//font-weight: $global-weight-bold;
	}

	.restaurant-logo {
		margin-bottom: 2rem;

		img {
			max-height: rem-calc(128);
		}

		@include breakpoint(small only) {
			img {
				margin: 0 auto;
				display: block;
			}
		}

		@include breakpoint(large) {
			margin-bottom: 0;

			text-align: center;
		}

		.logo-container {
			+ .logo-container {
				margin-top: $paragraph-margin-bottom;
				padding-top: $paragraph-margin-bottom;
				border-top: solid 2px;
			}
		}
	}

	.restaurant-copy {
		@include breakpoint(large) {
			padding: {
				left: 2rem;
				right: 4rem;
			}
		}
	}

	.restaurant-hours {
		p:last-child { margin-bottom: 0; }
	}

	// @include breakpoint(medium up) {
	// 	margin-top: 5rem;
	// }

	// + .row-full-width {
	// 	margin-top: 5rem;
	// }
}



.restaurant-menu-section {
	// margin-top: 3rem;
	margin-top: 0;
	padding-top: 2rem;
	padding-bottom: 2rem;
	border-top: 1px solid $tan-border-color;

	.menu-container {
		text-align: center;

		.learn-more-button {
			width: 100%;
			margin-top: .5rem;
			margin-right: 8px;

			@include breakpoint (medium up) {
				margin-top: 0;
				width: auto;
			}
		}
	}

	@include breakpoint (medium up) {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
}

// .module-event-posts {
	// @include breakpoint (medium only) {
	// 	.featured-post:last-child {
	// 		display: none;
	// 	}
	// }

	// @include breakpoint (large up) {
	// 	.featured-post:last-child() {
	// 		display: inline-block;
	// 	}
	// }
// }
