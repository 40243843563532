// CONTROL SETTINGS
main {
	p, span {  
		font-size: rem-calc(16); 
		letter-spacing: .015rem;
		line-height: $global-lineheight;
	}

	@include breakpoint (small only) {
		p { margin-bottom: 1rem; }
	}

	.content-wrapper { 
		margin: {
			left: auto;
			right: auto;
		}
		max-width: 1280px; 
		
		a {
			color: $anchor-color;
			transition: color .25s ease-in-out;

			&:hover,
			&:active {
				color: $nav-active-color;
			}

			&.reg-button { color: $white; }
		}
	}
}

// FIX FOR Foundation float right - was causing overflow issues
.column:last-child:not(:first-child), 
.columns:last-child:not(:first-child) {
	float: left;
}

// ALTERNATE CONTENT BLOCKS
.alternating-rows:not(.inverse) {
	.featured-post:nth-child(even) {
		.featured-post-image {
			float: right;
		}
	}

	.text-image-block:nth-child(even) {
		.image-block {
			float: left;
		}
	}
}

.row .row { margin-right: 0; margin-left: 0; }

// FORM SETTINGS 
select {
	padding: {
		top: 1.35rem;
		right: 1rem;
		bottom: 0.8rem;
	}
	line-height: 1.5rem;
	background: {
		image: url('../images/down-arrow.svg');
		position: 100% 40%;
		repeat: no-repeat;
		size: 16px 11px;
	}
	
	@include breakpoint(small only) {
		padding-top:1.15rem;
		height: auto;
	}
}


// MISC SETTINGS
.page-title { 
	border-top: 1px solid $tan-border-color;
	padding-top: 2rem; 
	margin-bottom: 1rem;
	text-align: left;

	@include breakpoint(medium up) {
		padding-top: 3rem; 
		margin-bottom: 3rem;
		text-align: center;
	}
}

.centered { text-align: center; }

.border-top { 
	padding-top: 2rem;
	border-top: 1px solid $light-gray; 
	
	@include breakpoint (medium up) {
		margin-top: 2rem;
		padding-top: 3rem;
	}
}
.no-padding { padding: 0; }

.alternate { float: right; }


// BUTTONS
.reg-button, .learn-more-button,
.solid-bg-button, .submit-button {
	padding: 1.35rem 1.65rem 1.1rem;
	display: inline-block;
	font: {
		size: rem-calc(14px);
		weight: $global-weight-bold;
	}
	line-height: 1rem;
	transition: color .25s ease-in-out, background-color .25s ease-in-out;

	@include breakpoint(medium up) {
		padding: 1.45rem 1.65rem 1.15rem;
		font-size: rem-calc(14px);
		letter-spacing: rem-calc(0.5px);
	}
}

.reg-button,
.learn-more-button {
	margin-top: 1rem;
	margin-bottom: .25rem;
	line-height: 1rem;
	text-transform: uppercase;
}

.reg-button {
	//padding: 1.25rem 1.65rem 1.15rem;
	border: 1px solid $white;
	color: $white;

	&:hover {
		border: 1px solid $nav-hover-color;
		background-color: $nav-hover-color;
		color: $white;
	}
}

.learn-more-button {
	border: 1px solid $nav-hover-color;
	color: $nav-hover-color;

	&:hover {
		background-color: $nav-hover-color;
		color: $white !important;
	}
}

.solid-bg-button,
.submit-button {
	margin: 1rem 0 0;
	background: $nav-hover-color;
	border: none;
	color: $white;

	&:hover {
		background-color: $nav-active-color;
	}
}

.solid-bg-button {
	text-transform: uppercase;
	border: 1px solid $nav-hover-color;

	&:hover {
		background-color: transparent;
		border-color: $nav-active-color;
		color: $nav-hover-color;
	}
}

.submit-button { width: 100% !important; }


// Dev note: not sure if this version is needed - check
.large-button {
	.rg-btn {
		width: 100%;
		padding: 1.5em;
		font-size: 1.1em;
	}
}


// CONTENT SECTION STYLING
.content {
	margin: {
		top: 2rem;
		bottom: 2rem;
	}

	h2 { font-size: 1.15rem; }
	h3 { font-size: 1rem; }

	a {
		color: $nav-hover-color;
		transition: color .15s ease-in-out;

		&:hover, &:active {
			color: $nav-active-color;
		}
	}

	section { overflow: inherit; }
}

.centered-title-block {
	margin: 2rem auto 3rem;
	text-align: center;

	a { font-weight: $global-weight-bold; }

	h2 { margin-bottom: 1rem; }

	a { 
		color: $nav-hover-color;
		&:hover { color: $nav-active-color; }
	}

	@include breakpoint(medium up) {
		margin: 3rem auto 4rem;
	}
}

.default-modal {
	transform: translateY(10%);

	&.no-background {
		background: none;
		border: none;
	}
	
	&.reveal { 
		padding: 2rem;
		width: 90%;

		img { width: 100%; }

		@include breakpoint(medium up) {
			width: 75%;
		}
	}

	& button {
		position: absolute;
		top: .5rem;
		right: .2rem;
		color: $black;
		font-size: rem-calc(18px);
	}
}


/********* GLOBAL SETTINGS & MODULES *********/

// Internal Nav
.module-internal-nav {
	clear: both;
	margin-left: 0;
	margin-right: 0;
	@include cristobal-uppercase();

	letter-spacing: .5px; // added in for texta

	display: inline-block;
	width: 100%;
	list-style: none;
	font-weight: $global-weight-bold;

	li { 
		margin: 0 .75rem;
		display: inline-block;
	
		a { 
			color: $nav-hover-color; 
			transition: color .15s ease-in-out;
			
			&.active { 
				color: $nav-link-color; 
			}

			&:hover,
			&.active &:hover {
				color: $nav-active-color; 
				border-bottom: 2px solid $nav-active-color;
			}
		}

		&:first-child { margin-left: 0; }
		&:last-child { margin-right: 0; }
	}
}

section {
	@include clearfix;
	position: relative;
	overflow: hidden;

	&.split-thirds {
		background-color: #f5f5f5;

		@media #{$medium-up} {
			> .row {
				padding-top: 70px;

				&:last-child {
					padding-bottom: 90px;
				}
			}
		}	
	}
}

// VIDEO MODAL
.video-modal {
	position: absolute;
	top: 50% !important;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	outline: none;
	border: none;

	&.no-background {
		background: none;
    	border: none;
	}

	button { 
		position: absolute;
		top: 36px;
		right: 2rem;
		color: #fff;
		font-size: 1.2rem;
	}
}

// 404 Page
.four-o-four-page {
	height: 100vh;

	.four-o-four-wrapper { 
		position: relative;
		height: 100%;
		width: 100%;

		.four-o-four-message {
			position: absolute;
		    top: 50%;
		    left: 50%;
		    transform: translate(-50%, -50%);
			color: $white; 

			img {
				@include breakpoint(small only) {
					display: none;
				}

				max-width: 35%;
				margin: 0 auto;
			}

			h2 { 
				margin: {
					top: 2rem;
					bottom: 0;	
				}
				font-family: $body-font-family;
				text-transform: uppercase;
			}

			p { 
				margin-bottom: 1rem;
				text-align: center; 
			}
		}
	}	
}

// SITEMAP 
.header-carousel {
	&.sitemap-header,
	&.sitemap-header .img-fill {
		height: 55vh;
	}

	&.sitemap-header {
		h2 { margin: 2rem 0 0 !important; }
	}
}

.sitemap-logo-outside {
	width: 100%;

	.sitemap-logo {
		width: 50%;

		img {
			@include breakpoint(small only) {
				display: block !important;
			}
		}

		@include breakpoint(medium up) {
			width: 40%;
		}
	}
}

.sitemap-block {
	padding: 3rem 0;

	.sitemap-nav {
		-webkit-column-count: 1; /* Chrome, Safari, Opera */
		-moz-column-count: 1; /* Firefox */
		column-count: 1;
		list-style: none;
		font-size: 1.25rem;
		
		@include breakpoint(medium up) {
			-webkit-column-count: 2; /* Chrome, Safari, Opera */
			-moz-column-count: 2; /* Firefox */
			column-count: 2;
		}

		a {
			&:hover { color: $nav-hover-color; }
		}

		.sub-menu {
			margin-left: 0;
			list-style: none;
		}
	}
}


// MISC 
.full-width-flex-content {
	section > .row { max-width: 100%; }
}