/* MODULE CONTENT BLOCKS */

// Intro Paragraph
.module-intro-block,
.module-plain-content-block,
.module-review-block {
	margin: 2rem auto;
	text-align: center;

	@include breakpoint(small only) {
		margin: .85rem auto 2rem;

		h2, p, ul {
			text-align: left;
		}

		h2 { margin-bottom: 1.25rem; }
	}
}

.module-intro-block {
	p:first-of-type {
		font-weight: $global-weight-bold;
		font-size: rem-calc(18);
	}
	// p:last-of-type { margin-bottom: 0; }
}

.module-review-block {
	.signature { text-transform: uppercase; }

	@include breakpoint(large up) {
		margin: rem-calc(75) auto;
	}
}

// Plain Content block
.module-plain-content-block {
	text-align: left;
}

// MODULE FEATURED POST
.module-featured-post,
.module-event-posts,
.blog-post {
	.featured-post {
		float: none;

		.featured-post-info {
			margin: rem-calc(45px) auto;
			width: 90%;

			h2 {
				font: {
					size: rem-calc(18px);
					weight: $global-weight-bold;
				}
			}

			a, button { margin-top: 0; }
		}

		@include breakpoint (medium up) {
			float:left;

			&:nth-child(even) {
				float: right;
				margin-right: 0;
			}
		}
	}

	@include breakpoint (small only) {
		padding: {
			left: 0;
			right: 0;
		}
	}
}

.module-event-posts {
	text-align: center;

	.featured-post {
		float: none;
		display: inline-block;

		&:last-child {
			float: none;
			display: inline-block;
		} //overrides foundation styling to remove last child

		@include breakpoint(medium up) {
			&:nth-child(even) {
				float: none;
				margin-right: 0;
			}
		}

		.featured-post-info { text-align: left; }
	}
}

.module-featured-post,
.blog-post {
	.featured-post, .grid-sizer {
		width: 100%;

		@include breakpoint(medium up) {
			width: 49%;
		}
	}

	.gutter-sizer {
		@include breakpoint(medium up) {
			width: 2%;
		}
	}
}

// EVENTS PAGE TEMPLATE POSTS
.module-event-posts {
	.featured-post {
		.featured-post-image {
			position: relative;

			.img-fill {
				padding-top: percentage(764/764);
				height: 0;
				overflow: hidden;

				img {
					object-fit: cover;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}

			.glyph {
				position: absolute;
				top: 1rem;
				left: 0;
				padding: 1.25rem 1.5rem .8rem;
				display: block;
				background-color: rgba(#F7F3EF,.95);
				font: {
					size: rem-calc(14px);
					weight: $global-weight-bold;
				}
				text-transform: uppercase;
				z-index: 5;
			}
		}
	}
}

// GENERAL MODULE CONTENT BLOCK
.module-content-block {
	.rooms-info-block {
		padding-left: .25rem;
		padding-right: .25rem;

		.rooms-info-wrapper {
			display: table;
			height: 100%;
			width: 100%;
			border: 1px solid $tan-border-color;

			.rooms-overlay-info {
				padding: 3rem;
				display: table-cell;
				height: 100%;
				width: 100%;
				vertical-align: middle;

				h2 {
					font: {
						size: rem-calc(18px);
						weight: $global-weight-bold;
					}
				}

				a { display: inline-block; }
			}
		}
	}
}


// FEATURED POST & CONTENT BLOCK
.module-featured-post,
.module-content-block,
.blog-post {
 	display: block;

	.featured-post {
		.featured-post-image {
			position: relative;

			.img-fill {
				padding-top: percentage(764/764);
				height: 0 !important;
				overflow: hidden;

				img {
					object-fit: cover;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border-radius: 2px;
				}
			}

			.img-fill-full { 
				padding-top: 100%; 
			}

			.glyph {
				position: absolute;
				top: 1rem;
				left: 0;
				padding: 1.25rem 1.5rem .8rem;
				display: block;
				background-color: rgba(#F7F3EF,.95);
				font: {
					size: rem-calc(14px);
					weight: $global-weight-bold;
				}
				text-transform: uppercase;
				z-index: 5;
			}

			@include breakpoint(medium up) {
				.image-cover { height: 100%; }
			}
		}

		.featured-post-image,
		.rooms-info-block {
			margin-bottom: 1rem;
			padding-left: 0;
			padding-right: 0;
			overflow: hidden;

			p:last-of-type { margin-bottom: 0; }

			&.no-padding {
				padding-left: 0;
				padding-right: 0;
			}

			@include breakpoint (medium up) {
				padding-left: .5rem;
				padding-right: .5rem;
			}
		}
	}
}

// BLOG POST {
.blog-post {
	margin-top: 2rem;
}

.other-promos {
	.featured-post {
		width: 100%;

		@include breakpoint(medium up) {
			width: 49.5%;
		}

		.featured-post-image {
			padding-left: 0;
			padding-right: 0;
		}
	}

	// &.promo-image-sizing {
	// 	.featured-post-image {
	// 		.img-fill {
	// 			padding-top: 0;
	// 			height: auto;
	// 			img {
	// 				position: relative;
	// 			}
	// 		}
	// 	}
	// }
}

// SINGLE OFFERS PAGE
.other-offers {
	padding-top: 2rem;
	padding-left: .5rem;
	padding-right: .5rem;
	border-bottom: 1px solid $tan-border-color;

	> h2 { text-align: center; }

	@include breakpoint(medium up) {
		margin-top: 3rem;
		padding-top: 3rem;
	}
}

// HOME OFFERS SECTION
.home-offers {
	margin-top: 0;
	padding: 0;
}