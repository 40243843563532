// CSS rules for the contact page template

.location-map {
	position: relative;
	height: 55vh;
	width: 100%;

	&.contact-map {
		margin-bottom: 2rem;

		@include breakpoint(medium up) {
			margin-bottom: 3rem;
		}

		#map {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
		}
	}
}
