// FOOTER

footer {
	padding: 0 1.5rem $small-padding;
	padding-bottom: $small-padding;
	@include breakpoint(medium up) {
		padding-right: rem-calc(152);
	}

	.footer-links {
		font-size: rem-calc(14px);
		font-weight: $global-weight-bold;
		letter-spacing: 1px;
		text-transform: uppercase;

		@include breakpoint(medium up) {
			@include menu-simple;
			margin: rem-calc(8) 0 rem-calc(24);
			padding-top: $small-padding;
			padding-left: 1rem;
			display: block;
		}

		@include breakpoint(large) {
			margin: $small-padding 0 rem-calc(24);
		}

		> li:last-child {
			margin-right: 0;
		}

		a {
			color: $black;
			transition: color .1s ease-in-out;

			&:hover {
				color: $nav-hover-color;
				border-bottom: 2px solid $nav-hover-color;
			}
		}
	}

	.mailing-list {
		float: right !important;
		margin-top: .75rem;

		#mc_embed_signup {
			width: 100%;
			form {
				margin: 0;
			}

		#mc_embed_signup_scroll {
			display: flex;
			@include breakpoint(medium down) {
				flex-direction: column;
			}
			.mc-field-group {
				width: 100%;
				padding-bottom: 0;
			}
			.simple-cta__input {
				border: none;
				border-radius: 0;
				background-color: $tan-border-color;
				text-indent: 4%;
				@include breakpoint(small down) {
					background-color: rgb(240, 232, 222);
				}

				&:focus {
					background-color: rgba(255, 255, 255, 0.75);
					@include breakpoint(small down) {
						background-color: rgb(240, 232, 222);
					}
				}
			}

			.helper_text, .helper_text + div {
				background-color: transparent !important;
			}

			.simple-cta__button {
				font-family: $body-font-family;
				font-size: rem-calc(14);
				text-transform: uppercase;
				font-weight: bold;
				min-width: rem-calc(225);
				background-color: $tan-background-color;
				color: $promo-color;
				height: rem-calc(50);
				border: 2px solid $tan-border-color;
				border-radius: 0;
				margin: 0;
				@media (max-width: 1300px) {
					min-width: auto;
				}
				@include breakpoint(medium down) {
					width: 100%;
				}
				@include breakpoint(small down) {
					background-color: #b83020;
					color: $body-background;
				}
				&:hover, &:focus {
					background-color: #b83020;
					color: $body-background;
				}
			}
		}
	}

		input[type="email"],
		button {
			padding: 1.35rem 2rem .9rem;
			height: 50px;
			font-size: rem-calc(14);
			font-weight: $global-weight-bold;
			letter-spacing: .5px; // added in for the texta font replacement
			text-transform: uppercase;
		}

		input[type="email"] {
			// padding: rem-calc(22) rem-calc(30) rem-calc(20);
			background: $tan-border-color;
			border: 2px solid $tan-border-color;
			border-right: none;
			color: $input-text-color;
			text-align: center;

			&:focus {  background: rgba(255,255,255,.75); }

			@include breakpoint(medium up) {
				text-align: left;
			}
		}

		button {
			// padding: rem-calc(18) rem-calc(30) rem-calc(20);
			background: $tan-background-color;
			border: 2px solid $tan-border-color;
		}

		.subscribe {
			margin: 0;
			padding-top: 1.15rem;
			color: $nav-link-color;

			&:hover { color: $body-background; }
		}

		@include breakpoint(medium up) {
			margin-top: 1.75rem;
		}
	}
}
