[type=color],
[type=date],
[type=datetime-local],
[type=datetime],
[type=email],
[type=month],
[type=number],
[type=password],
[type=search],
[type=tel],
[type=text],
[type=time],
[type=url],
[type=week],
select,
textarea {
	opacity: 0.55;
	padding: rem-calc(16);
	margin-bottom: 0;
	transition: $global-transition;
	background-color: #f5f5f5;

	@media #{$large-up} {
		font-size: rem-calc(14);
	}

	&:valid,
	&:focus {
		opacity: 1;
		background-color: $white;
		color: $black;
		transition: $global-transition;
	}

	&[readonly] {
		opacity: 1;
	}
}

input {
	&[type=radio]:not(old) {
		width: rem-calc(24);
		height: rem-calc(24);
		display: inline-block;
		vertical-align: middle;
		margin: 0;
		font-size: 1em;
		line-height: 1;
		display: none;

		+ label {
			position: relative;
			display: inline-block;
			padding-left: rem-calc(36);
			line-height: rem-calc(24);

			&:before,
			&:after {
				position: absolute;
				content: '';
				display: inline-block;
				top: 50%;
				border-radius: 50%;
			}

			&:before {
				left: 0;
				width: rem-calc(24);
				height: rem-calc(24);
				margin-top: rem-calc(-12);
				border: solid 1px $light-gray;
				background-color: $white;
				z-index: 1;
			}
			&:after {
				left: rem-calc(12);
				width: rem-calc(12);
				height: rem-calc(12);
				margin-top: rem-calc(-6);
				margin-left: rem-calc(-6);
				z-index: 2;
			}
		}

		&:checked {
			+ label {
				&:after {
					background-color: $primary-color;
				}
			}
		}
	}
}

select {
	padding-right: 65px;
	background: {
		// image: linear-gradient(90deg, transparent 49%, #{$light-gray} 50%, transparent 51%), svg-inline(dropdown);
		position: calc(100% + 16px) center, calc(100% + 48px) center;
		size: 1px 40px, 16px auto;
	}
}

input.datepicker {
	background: {
		// color: $input-background;
		// image: svg-inline(datepicker);
		position: calc(100% - 16px);
		size: auto 14px;
		repeat: no-repeat;
	}
	color: $black;
}

[type=submit] {
	background-color: $primary-color;

	&:hover,
	&:focus {
		background-color: scale-color($primary-color, $lightness: -15%);
	}
}

form {
	.row + .row { margin-top: rem-calc(16) !important; }
	.small-12 + .small-12 { margin-top: rem-calc(16); }
}

.input-group {
	margin-bottom: 0;

	.input-label,
	.input-wrapper {
		display: table-cell;
		vertical-align: middle;
	}

	.input-label {
		width: 1%;

		label {
			font-weight: $global-weight-bold;
			text-transform: uppercase;
			margin-right: rem-calc(24);
			white-space: nowrap;
		}
	}

	.input-wrapper {
		width: 100%;
	}
}

#subForm {
	@include breakpoint(small only) {
		> .row {
			margin-left: 1rem;
			margin-right: 1rem;
		}
	}
}

/* FORM BLOCK */
.module-form {
	margin: 4rem auto;

	.module-form-content {
		margin-bottom: 2rem;
		padding: 0 0 2rem 2rem;

		p:first-of-type {
			font-weight: $global-weight-bold;
		}
		p:last-of-type {
			margin-bottom: 0;
		}

		@include breakpoint(medium) {
			padding-top: 2rem;
		}

		@include breakpoint(large up) {
			padding-top: 3rem;
		}
	}


	.form-wrapper {
		margin: 0 auto 2rem;
		//float: none !important;
		padding: 0;
		overflow: hidden;
		border: 1px solid $tan-dark-border-color;

		.form-intro { 
			padding: 1rem 0 .9rem;
			border-bottom: 1px solid $tan-dark-border-color;

			h3 {
				margin-bottom: 0;
				padding: 1rem 2rem;
				display: block;
				font: {
					size: 1rem;
					weight: $global-weight-bold;
				}
				line-height: 1rem;
				text-align: center;
			}

			p {
				margin-bottom: 0;
				text-align: center;
			}
		}
	
		form {
			float: none;
			padding: 2rem;
			overflow: hidden; 	
			
			input, select, textarea {
				outline: none;
				font-weight: $global-weight-bold;
				text-transform: uppercase;

				&:focus {
					background-color: #f5f5f5;
				}
			}

			.wpcf7-form-control-wrap {
				width: 100%;
				display: inline-block;

				&:nth-of-type(even) {
					float: right;
				}

				&.notes { width: 100%; }

				@include breakpoint(large up) {
					width: 49%; 
				}
			}

			input, select { 
				margin-bottom: 1rem;
				width: 100%;
				border: 1px solid $tan-dark-border-color;

				&:nth-child(even) {
					float: right;
				}

				&::-webkit-inner-spin-button,
				&::-webkit-calendar-picker-indicator {
					display: none;
				  -webkit-appearance: none;
				}
			}

			input {
				float: left;
				padding: 1.25rem 1rem 0.9rem;
				line-height: normal;
				height: inherit;
				vertical-align: middle;
				
				&.submit-button { 
					margin-bottom: 0;
					border: none; 
				}

				&:nth-child(even) {
					float: right;
				}
			}

			.form-datepicker {
				background: {
					image: url('../images/calendar.svg');
					position: 95% center;
					repeat: no-repeat;
				}
			}

			textarea {
				clear: both;
				min-height: rem-calc(120px);
				max-height: rem-calc(245px);
			}

			@include breakpoint(medium up) {
				padding: 2rem;
			}

			.wpcf7-mail-sent-ok {
				clear: both;
				padding: {
					top: 2rem;
					bottom: 0;
				}
				border: none;
				text-align: center;
			}
		}

		@include breakpoint(large up) {
		// 	float: left !important;
			margin-bottom: 4rem;
		}
	}
}