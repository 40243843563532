// CSS rules for the rooms template

.module-rooms-grid {
	@include breakpoint(large) {
		margin-top: rem-calc(66.5);
	}

	@include breakpoint(xlarge) {
		margin-top: rem-calc(68);
	}

	@include breakpoint(xxlarge up) {
		margin-top: rem-calc(72);
	}

	.rooms-block,
	.rooms-info-block {
		margin-bottom: .5rem;
		padding-left: .25rem;
		padding-right: .25rem;

		.row {
			position: relative;
			margin-left: 0;
			margin-right: 0;
			padding-top: percentage(502/512);
			overflow: hidden;
			height: 0;
			min-height: 100%;
			max-width: 100%;
			background: {
				position: center center;
				repeat: no-repeat;
				size: cover;
			}
			border-radius: 2px;

			.rooms-overlay {
				display: table;
				position: absolute;
				top: 0;
				left: 0;
				height: 100.5%;
				width: 100.5%;
				background-color: rgba(0,0,0,0);
				transition: background-color .15s ease-in-out;

				span.reg-button {
					position: absolute;
					left: 2rem;
					bottom: 2rem;
					padding: 1.45rem 1.65rem 1.15rem;
					background-color: rgba(0,0,0,.25);
					font-weight: $global-weight-bold;
					font-size: rem-calc(14);
					letter-spacing: rem-calc(0.75);
					text-transform: uppercase;
					transition: opacity .15s ease-in-out;

					&:hover,
					&:active {
						border: 1px solid $white;
					}

					@include breakpoint(small only) {
						left: 1rem;
						bottom: .75rem;
						max-width: 225px;
						line-height: 1.25;
					}
				}

				.rooms-overlay-info {
					padding: 4rem;
					display: none;
					height: 100%;
					width: 100%;
					background-color: rgba(#EDE4D6, .9);
					opacity: 0;
					text-align: center;
					vertical-align: middle;
					transition: opacity .15s ease-in-out;

					p { margin-bottom: 0; }
				}

				&:hover {
					background-color: rgba(0,0,0,0.035);
				}
			}

			@include breakpoint(small only) {
				height: auto;
			}
		}

		@include breakpoint(medium up) {
			&.large-8 .row { padding-top: percentage(504/1032); }
		}
	}

	.rooms-block {
		a {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
		}
	}

	.rooms-info-block {
		padding-left: .25rem;
		padding-right: .25rem;

		.rooms-info-wrapper {
			position: absolute;
			top: 0;
			left: 0;
			display: table;
			display: -webkit-inline-box;
			-webkit-box-pack: center;
			-webkit-box-align: center;
			height: 100%;
			width: 100%;
			border: 1px solid $tan-border-color;

			@include breakpoint(small only) {
				position: relative;
			}

			.rooms-overlay-info {
				padding: 3rem;
				display: table-cell;
				height: 100%;
				width: 100%;
				vertical-align: middle;

				a { display: inline-block; }

				@include breakpoint(large only) {
					padding: 1.75rem;
					ul { margin-bottom: 0; }
				}

				@include breakpoint(small only) {
					padding: 1.25rem;
				}
			}
		}

		@include breakpoint(small only) {
			min-height: 320px;

			.row { padding-top: 0; }
		}
	}
}


// OTHER ROOMS SLIDER
.rooms-carousel-wrapper {
	overflow: hidden;

	@include breakpoint(medium up) {
		margin-top: 5rem;
	}

	h2 {
		margin-bottom: rem-calc(20);
		font-weight: $global-weight-bold;
		text-align: left;

		@include breakpoint (medium up) {
			text-align: center;
		}
	}

	.other-room-carousel {
		margin-bottom: rem-calc(24px);
		position: relative;
		width: 100%;
		overflow: hidden;

		@include breakpoint(medium up) {
			margin-bottom: 0;
		}

		.carousel-prev,
		.carousel-next {
			position: absolute;
			top: 50%;
			transform: translateY(-.5rem);
			z-index: 5;
			background: white;
			font: {
				size: rem-calc(17);
				weight: $global-weight-bold;
			}

			span {
				&.icon-arrow-left,
				&.icon-arrow-right {
					color: red;
				}
			}
		}

		.carousel-prev {
			left: 0;
			padding: .85rem .35rem .75rem .20rem;
			border-top-right-radius: 2px;
			border-bottom-right-radius: 2px;
		}
		.carousel-next {
			right: 0;
			padding: .85rem .20rem .75rem .35rem;
			border-top-left-radius: 2px;
			border-bottom-left-radius: 2px;
		}

		.slick-list {
			margin: 0 -.25rem;


			.item {
				padding-left: .25rem;
				padding-right: .25rem;

				a {
					display: block;
					outline: none;
				}

				.img-fill {
					padding-top: percentage(400/383);
					height: 0;
					overflow: hidden;

					img {
						object-fit: cover;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}

					.info {
						position: absolute;
						top: 0;
						left: 0;
						height: 100%;
						width: 100%;
						background-color: rgba(0, 0, 0, 0.15);
						background-size: cover;
						transition: background-color .15s ease-in-out;

						span {
							position: absolute;
							bottom: 2rem;
							left: 0;
							right: 0;
							margin: 0 auto;
							padding: 1rem 1.1rem .65rem;
							display: inline-block;
							width: 75%;
							background-color: none;
							border: 1px solid $white;
							opacity: 1;
							color: $white;
							font: {
								size: rem-calc(14);
								weight: $global-weight-normal;
							}
							letter-spacing: .5px;
							text-transform: uppercase;
							transition: opacity .15s ease-in-out;
							z-index: 1;

							@include breakpoint (medium up) {
								margin: 0;
								padding: 1.15rem 1.5rem .75rem;
								left: 2rem;
								right: auto;
								width: auto;
							}
						}

						.overlay-info-wrapper {
							padding: 2rem;
							display: table;
							height: 100%;
							width: 100%;
							opacity: 0;
							transition: opacity .15s ease-in-out;

							.overlay-info {
								height: 100%;
								width: 100%;
								display: table-cell;
								vertical-align: middle;

								h2 {
									font-size: 1.75rem;
									margin-bottom: .75rem;
								}

								p {
									margin-bottom: 0;
									line-height: 1.5;
								}
							}

							@include breakpoint(large) {
								padding: 3rem;
							}

							@include breakpoint(xlarge) {

								h2 {
									margin-bottom: 1rem;
									font-size: 2rem;
								}

								p { line-height: 1.75; }
							}
						}

						&:hover {
							background-color: rgba($tan-border-color, .9);

							span {
								opacity: 0;
								bottom: 2rem;
							}

							@include breakpoint(medium down) {
								background-color: rgba(0, 0, 0, 0.15);
								span { opacity: 1; }
							}

							@include breakpoint(large) {
								.overlay-info-wrapper { opacity: 1; }
							}
						}
					}
				}
			}
		}
	}
}
