// Two Col List w/ Title
.module-two-up-list {
	margin-bottom: 1rem;
	margin-left: auto;
	margin-right: auto;

	h2 { 
		margin-bottom: 2rem;
		text-align: center; 
	}

	@include breakpoint (medium up) {
		margin-bottom: 3rem;

		h2 { margin-bottom: 3rem; }
	}

	ul { 
		margin-left: 0;
		list-style: disc;
		-webkit-column-count: 1;
		-moz-column-count: 1;
		column-count: 1;
		font-size: rem-calc(15);

		li { 
			margin-left: 1.75rem; 
			padding-bottom: 1rem;
			line-height: 1.35rem;
			-webkit-column-break-inside: avoid;
			page-break-inside: avoid;
			break-inside: avoid;
		}

		@include breakpoint(medium up) {
			margin-left: 1.25rem;
			-webkit-column-count: 2;
			-moz-column-count: 2;
			column-count: 2;
		}

		&:last-of-type { margin-bottom: 0; }
	}
}