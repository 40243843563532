.component-video-gallery {
	margin-top: 4rem;
	margin-bottom: 2rem;

	h2 { text-align: center; }

	.video-gallery-wrapper {
		position: relative; 
		margin-bottom: .5rem;
		padding-left: .25rem;
		padding-right: .25rem;
		overflow: hidden;

		.video-thumbnail {
			padding-top: percentage(400/710);
			overflow: hidden;
			background: {
				size: cover;
			}

			.thumbnail-overlay {
				position: absolute;
				top: 0;
				left: 0;
				height: 100.5%;
    			width: 100.5%;
				background-color: rgba(0,0,0,.25);
			}
		}

		.play {
			position: absolute;
			top: 50%;
			left: 50%;
			right: 50%;
			padding: 1rem;
			height: 58px;
			width: 58px;
			border: 1px solid $white;
			color: white;
			transform: translate(-50%, -50%);
		}
	}
}