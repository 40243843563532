// Additional non-Foundation variables;

$sectionSpacing: 64px;
$sectionSpacingMobile:32px;

$rowGutterMobile: 32px;
$rowGutterTablet: 64px;
$rowGutter: 40px;

$footer-bg: $white;

$global-transition: all 0.25s ease-in-out;