// ***********************
// Slick Carousel Settings
// ***********************

$slick-font-path: "../fonts/";
$slick-font-family: "fontello";
$slick-loader-path: "../images/";
$slick-arrow-color: $white;
$slick-dot-color: #e2e5e7;
$slick-dot-color-active: #a3abb1;
$slick-prev-character: '\e800';
$slick-next-character: '\e801';
$slick-dot-character: '\e805';
$slick-dot-size: 20px;
$slick-opacity-default: 1;
$slick-opacity-on-hover: 1;
$slick-opacity-not-active: 1;
$slick-dot-color-active: $white;
$slick-dot-color: #2F2F2F;

// ***********************
// Magnific Popup Settings
// ***********************

// overlay
$mfp-overlay-color:                   #0b0b0b;                    // Color of overlay screen
$mfp-overlay-opacity:                 0.8;                        // Opacity of overlay screen
$mfp-shadow:                          0 0 8px rgba(0, 0, 0, 0.6); // Shadow on image or iframe

// spacing
$mfp-popup-padding-left:              8px;                        // Padding from left and from right side
$mfp-popup-padding-left-mobile:       6px;                        // Same as above, but is applied when width of window is less than 800px

$mfp-z-index-base:                    1040;                        // Base z-index of popup

// controls
$mfp-include-arrows:                  true;                       // Include styles for nav arrows
$mfp-controls-opacity:                0.65;                       // Opacity of controls
$mfp-controls-color:                  #FFF;                       // Color of controls
$mfp-controls-border-color:           #3F3F3F; 	                  // Border color of controls
$mfp-inner-close-icon-color:          #333;                       // Color of close button when inside
$mfp-controls-text-color:             #CCC;                       // Color of preloader and "1 of X" indicator
$mfp-controls-text-color-hover:       #FFF;                       // Hover color of preloader and "1 of X" indicator
$mfp-IE7support:                      true;                       // Very basic IE7 support

// Iframe-type options
$mfp-include-iframe-type:             true;                       // Enable Iframe-type popups
$mfp-iframe-padding-top:              40px;                       // Iframe padding top
$mfp-iframe-background:               #000;                       // Background color of iframes
$mfp-iframe-max-width:                900px;                      // Maximum width of iframes
$mfp-iframe-ratio:                    9/16;                       // Ratio of iframe (9/16 = widescreen, 3/4 = standard, etc.)

// Image-type options
$mfp-include-image-type:              true;                       // Enable Image-type popups
$mfp-image-background:                #444 !default;
$mfp-image-padding-top:               40px;                       // Image padding top
$mfp-image-padding-bottom:            40px;                       // Image padding bottom
$mfp-include-mobile-layout-for-image: true;                       // Removes paddings from top and bottom

// Image caption options
$mfp-caption-title-color:             #F3F3F3;                    // Caption title color
$mfp-caption-subtitle-color:          #BDBDBD;                    // Caption subtitle color

// A11y
$mfp-use-visuallyhidden:              false;

// MFP additional

/**
 * Fade-zoom animation for first dialog
 */

/* start state */
.my-mfp-zoom-in {
	opacity: 0;

	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;



	-webkit-transform: scale(0.8);
	-moz-transform: scale(0.8);
	-ms-transform: scale(0.8);
	-o-transform: scale(0.8);
	transform: scale(0.8);
}

/* animate in */
.my-mfp-zoom-in.mfp-ready {
	opacity: 1;

	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}

/* animate out */
.my-mfp-zoom-in.mfp-removing {
	-webkit-transform: scale(0.8);
	-moz-transform: scale(0.8);
	-ms-transform: scale(0.8);
	-o-transform: scale(0.8);
	transform: scale(0.8);

	opacity: 0;
}

/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
	opacity: 0;
	-webkit-transition: opacity 0.3s ease-out;
	-moz-transition: opacity 0.3s ease-out;
	-o-transition: opacity 0.3s ease-out;
	transition: opacity 0.3s ease-out;
}
/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
	opacity: 0.8;
}
/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
	opacity: 0;
}
