// /****
//  *  Mobile Menu
//  *  ==================
//  */

.mobile-menu {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 125;
	width: 100%;
	height: 100vh;
	min-height: 100% !important;
	overflow-y:scroll;
	overflow-x:hidden;
	-webkit-overflow-scrolling: touch;
	display: none;
	background-color: rgba(43, 43, 43, 0.95);
	padding-bottom: rem-calc(56);

	.mobile-site-branding {
		width: 60%;
		max-width: 238px;
		margin: 1.10rem 0 0 0.25rem;
		position: absolute;
		top: 0;
		left: 0;
		padding: rem-calc(13.177);
		display: block;
		outline: none;
		background-color: transparent;
	}

	&.is-open {
		// Overlay navigation
		display: block;

		.mobile-site-branding {
			z-index: 126;
		}
	}

	li {
		padding: rem-calc(15px 0 8px);
		width: 100%;
		border-top: 1px solid rgba($white, .25);

		&:last-child {
			padding-bottom: rem-calc(10px);
			border-bottom: 1px solid rgba($white, .25);
		}
	}
}
