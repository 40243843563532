// Two Col List w/ Title
.module-video-embed {
	margin: {
		top: rem-calc(35);
		bottom: rem-calc(50);
	}

	.module-video-wrapper {
		position: relative;
		//padding-top: 56.5em;
		padding-top: 15.5em;

	    > div {
	    	position: absolute;
		    top: 0;
		    bottom: 0;
		    left: 0;
		    right: 0;
	    }
	}

	@include breakpoint(medium) {
		margin-bottom: rem-calc(70);

		.module-video-wrapper {
			padding-top: 26em;
		}
	}

	@include breakpoint(large up) {
		.module-video-wrapper { padding-top: 38em; }
	}
}

.video-modal {
	&.reveal {
		height: auto;
		min-height: auto;
	}
}