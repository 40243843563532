// Module - Text & Image (2UP content type)
.text-image-block {
	margin-left: 0;
	margin-right: 0;
}

.module-internal-nav {
	margin: {
		top: 2rem;
		bottom: 3.5rem;
	}

	@include breakpoint(medium up) {
		margin-bottom: 3.5rem;
	}
}

// Text & Image (2UP content type)
.module-text-image {
	margin-bottom: .5rem;

	> .row {
		margin-bottom: .5rem;
	}

	.image-block-carousel {
		.item,
		.item > div { 
			height: 100%;
		}
	}

	.text-block,
	.image-block,
	.image-block-carousel {
		padding-left: .25rem;
		padding-right: .25rem;

		// .row {
		// 	position: relative;
		// 	height: 0;
		// 	max-height: 600px;
		// 	overflow: hidden;
		// 	border-radius: 2px;

		.text-block-info-wrapper {
			position: relative;
			background: #F7F3EF;
			border: 1px solid #F0E8DE;

			@include breakpoint(medium up) {
				display: table;
				height: 100%;
				width: 100%;
			}

			.text-block-info { 
				padding: 2rem;
				display: table-cell;
				height: 100%;
				width: 100%;
				vertical-align: middle;

				h2 { 
					font: { 
						size: rem-calc(18px);
						weight: $global-weight-bold; 
					}
				}

				p { 
					margin-bottom: rem-calc(20);

					&:last-child,
					&.no-spacing {
						margin-bottom: 0; 
					}
				}

				a {
					display: inline-block; 
				}

				@include breakpoint(medium up) {
					padding: 3rem;
				}
			}
		}
	}

	.text-image-block {
		margin-left: auto;
		margin-right: auto;
	}

	.image-block,
	.image-block div {
		overflow:hidden;
		min-height: 249px;

		@include breakpoint(medium up) {
			min-height: 540px;
		}

		.row {
			margin: 0;
			height: 100%;
			overflow: hidden;
			background: {
				position: center center;
				repeat: no-repeat;
				size: cover;
			}
		}
	}

	.extra-images {
		.image-block-wrapper { 
			margin-top: .5rem; 
			margin-bottom: 0;

			img { border-radius: 2px; }
		}
	}
}


// Two Up Images 1/3 2/3 
.module-two-up-images {
	.image-block-wrapper {
		margin-bottom: .5rem;
		padding-left: .25rem;
		padding-right: .25rem;
	}

	.image-cover {
		position: relative;
		height: 0;
		padding: 0;

		img {
			position: absolute;
			top: 0;
		}
	}
}

.image-cover {
	position: relative;

	img { 
		max-width: none; 
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}

.module-multi-image-grid {
	margin-bottom: .35rem;

	.image-grid-container {
		width: 100%;
		overflow: hidden;

		@include breakpoint(medium up) {
			margin-bottom: .15rem;
			height: (763/1552 * 100vw);
			max-height: 763px;
		}

		@include breakpoint(small only) {
			.image-block {
				.image-cover { height: 96.5% }
			}
		}

		.image-block {
			&.half-height {
				margin-bottom: .5rem;
			}
		}
	}

	.image-block {
		position: relative;
		overflow: hidden;
		//float: left;

		&.columns {
			padding-left: .25rem;
			padding-right: .25rem;			
		}
		
		// @include breakpoint(small only) {
		// 	margin-bottom: .5rem;
		// }
		
		@include breakpoint(medium up) {
			height: 100%;
			
			.image-cover {
				width: 100%;
				height: 100%;
			}
			
			&.half-height {
				height: calc(50% - .25rem);
				margin-bottom: .5rem;
			}
		}

		.image-cover { 
			img { border-radius: 1px; }
		}
	}
}