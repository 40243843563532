.header-carousel,
.gallery-carousel,
.gallery-slide-nav {
	position: relative;
	height: 70vh;

	.img-fill { 
		height: 70vh;

		img { width: 100%; }
	}

	.slick-dots {
		bottom: 25px;

		@include breakpoint(medium up) {
			top: 50%;
			bottom: auto;
			right: 1.5rem;
			width: 30px;
			transform: translateY(-50%);
		}
	}

	.info {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: {
			color: rgba(0,0,0,.25);
			size: cover;
		}
		
		.slide-text {
			position: absolute;
			top: 50%;
			left: 50%;
			right: 50%;
			transform: translate(-50%, -50%);
			width: 80%;
			color: $white;
			letter-spacing: 1px;
			text-align: center;

			h2 { 
				font-size: rem-calc(36px); 
				line-height: 1.15;
			}

			@include breakpoint(medium up) {
				h2 { font-size: rem-calc(48px); }
			}
		}
		
		.play {
			border: 1px solid $white;
			height: 58px;
			width: 58px;
			font-size: rem-calc(15px);
		}
	}

	&.slick-slider { 
		margin-bottom: 1rem; 
	}

	@include breakpoint(large up) {
		//height: 85vh;
		height: 75vh;

		.img-fill { 
			// height: 80vh; 
			height: 75vh;
		}
	}

	// HOME CAROUSEL
	&.home-carousel {
		@include breakpoint(large up) {
			height: 85vh;
			
			.img-fill { 
				height: 85vh; 
			}
		}
	}
}

// TEXT IMAGE CAROUSEL BLOCK
.image-block-carousel {
	&.slick-slider { 
		margin-bottom: 0; 
		
		@include breakpoint(small only) {
			.slick-list,
			.itb-image { 
				height: 25vh !important;
			}
		}
	}

	.slick-dots {
		bottom: 25px;

		@include breakpoint(medium up) {
			top: 50%;
			bottom: auto;
			right: 1.5rem;
			width: 30px;
			transform: translateY(-50%);
		}
	}
}


// GALLERY CAROUSEL 
.gallery-carousel-wrapper {
	margin-top: rem-calc(66.5);

	.gallery-carousel,
	.gallery-slide-nav {
		height: auto;
		max-height: 80vh;
		outline: none;
		overflow: hidden; 

		.img-fill { height: auto; }

		&.slick-slider { margin-bottom: .5rem; }
		
		.item { padding: 0; }
		
		.carousel-prev,
		.carousel-next {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			z-index: 5;
			background: white;
			font: {
				size: rem-calc(17); 
				weight: $global-weight-bold;
			}

			span {
				&.icon-arrow-left, 
				&.icon-arrow-right { color: red; }
			}
		}

		.carousel-prev { 
			left: 0; 
			padding: 1.25rem .55rem 1rem .40rem;
		}
		.carousel-next { 
			right: 0; 
			padding: 1.25rem .40rem 1rem .55rem;
		}
	}

	// Gallery Carousel - Specific Styling
	.gallery-carousel {
		.image-cover {
			height: 65vh;

			img {
				object-fit: cover;
				//position: absolute;
				top: 50%;
				left: 50%;
				//transform: translate(-50%, -50%);
				//width: 100%;
				//height: 100%;
			}
		}

		.carousel-prev,
		.carousel-next {
			span {
				&.icon-arrow-left, 
				&.icon-arrow-right { font-size: 1.1rem; }
			}

			@include breakpoint(medium) {
				span {
					&.icon-arrow-left, 
					&.icon-arrow-right { font-size: 1.25rem; }
				}
			}

			@include breakpoint(large) {
				span {
					&.icon-arrow-left, 
					&.icon-arrow-right { font-size: 1.5rem; }
				}
			}
		}

		@include breakpoint(small only) {
			.carousel-prev { padding: .75rem .35rem .55rem .20rem; }
			.carousel-next { padding: .75rem .20rem .55rem .35rem; }
		}
	}

	//Gallery Slide Nav 
	.gallery-slide-nav-wrapper {
		margin-top: 0;
		overflow: hidden;
		
		.gallery-slide-nav {
			width: 100%;
			position: relative;
			overflow: hidden;

			.slick-list {
				margin: 0 -.25rem;

				.item {
					padding-left: .25rem;
					padding-right: .25rem;
					outline: none;

					a { 
						display: block; 
						outline: none;
					}

					.img-fill {
						padding-top: percentage(400/384);
						height: 0;
						overflow: hidden;

						img {
							object-fit: cover;
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							width: 100%;
							height: 100%;
						}
					}
				}
			}

			.carousel-prev { padding: .75rem .35rem .55rem .20rem; }
			.carousel-next { padding: .75rem .20rem .55rem .35rem; }
		}
	}
}

.slick-slider {
	margin-bottom: 0;
}

.img-fill {
  position: relative;
  width: 100%;
  display: block;
  overflow: hidden;
  background: {
		position: 50%;
		repeat: no-repeat;
		size: cover;
	}
  text-align: center;
}

.slideshow-container {
	position: relative;

	.slick-dots {
		margin-left: 0;
		margin-bottom: rem-calc(7.5);
		bottom: 0;

		@media #{$large-up} { margin-bottom: rem-calc(40); }

		li {
			&, button, button:before {
				width: $slick-dot-size;
				height: $slick-dot-size;
			}
		}
	}
}

.single-slide {
	.slick-prev,
	.slick-next {
		width: auto;
		height: auto;
		&:before {
			@include icon-font;
			font-size: rem-calc(36);

			@media #{$large-up} {
				font-size: rem-calc(28);
			}
		}
	}

	.slick-slide {
		position: relative;
		background: {
			size: cover;
			repeat: no-repeat;
			position: center center;
		}
		img {
			visibility: hidden;
			width: 100%;

			@media #{$small-only} {
				height: 0;
				padding-top: 100%;
			}

			@media #{$medium-only} {
				height: 600px;
			}
		}
	}

	&:focus {
		outline: 0;
	}

	.slick-prev {
		left: rem-calc(16);
		@extend .icon-arrow-left;
	}

	.slick-next {
		right: rem-calc(16);
		@extend .icon-arrow-right;
	}

	@media #{$large-up} {
		.slick-prev {
			left: rem-calc(32);
		}
		.slick-next {
			right: rem-calc(32);
		}
	}
}

.slick-prev,
.slick-next {
	&:before {
		@include icon-font;
	}
}

.slick-prev {
	@extend .icon-arrow-left;
}

.slick-next {
	@extend .icon-arrow-right;
}

.slick-slider .slick-track, .slick-slider .slick-list {
	border-radius: 2px;
}