.pre-loader {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100%;
	background-color: $tan-background-color;
	z-index: 999;

	svg {
		position: absolute;
		top: 52%;
		left: 50%;
		max-width: 32%;
		transform: translate(-50%, -50%);

		@include breakpoint(small only) {
			max-width: 75%;
		}

		.rays { opacity: 0; }
	}
}