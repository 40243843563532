.modal-full-promo {
	margin-bottom: 1rem;
	overflow: hidden;
	border-radius: 2px;

	&.testimonial,
	&.promotion {
		max-height: 560px;

		h3, p { font-weight: $global-weight-bold; }

		// INFO BLOCK
		.info {
			position: relative;
			top: 0;
			left: 0;
			padding: 9rem 0;
			height: 100%;
			width: 100%;
			background-size: cover;

			&.light-overlay {
				background-color: rgba(255,255,255,.7);
				color: $black;
			}
			&.dark-overlay {
				background-color: rgba(0,0,0,.25); 
				color: $white;
			}
		}
	}

	// TESTIMONIAL
	&.testimonial {
		.testimonial-quote {
			text-transform: uppercase;

			h3 {
				margin: 0 auto;
				max-width: rem-calc(1024);
				width: 80%;

				font: {
					family: "WindsorD", sans-serif;
					size: rem-calc(40px);
				}

				line-height: 1.1;
			}

			.testimonial-author {
				margin: 1rem 0 0;
				font-size: rem-calc(14px);
				letter-spacing: 1px;
			}
		}
	}

	// PROMOTION
	&.promotion {
		max-height: none;
		letter-spacing: 1px;

		.info {
			margin: 0 auto;
			padding: rem-calc(75px) 0;
			text-align: center;

			.promo-block {
				display: inline-block;
				max-width: 750px;

				.promo-logo {
					// max-width: 190px;
					max-width: 300px; }

				.promo-blurb {
					margin: rem-calc(35px) 0;
					padding: 0 2rem;
					

					p {
						font: {
							size: rem-calc(17px);
							weight: $global-weight-bold;
						}
						letter-spacing: 0.045rem;
						line-height: 1.75rem;
					}
				}
			}
		}
	}

	// BREAKPOINT SETTINGS
	@include breakpoint(medium) {
		&.testimonial {
			.info {
				padding: 13rem 0;
			}

			.testimonial-quote {
				h3 { font-size: rem-calc(32px); }
			}
		}

		&.promotion {
			.info {
				padding: rem-calc(100px) 0;

				.promo-blurb { margin: rem-calc(50px) 0; }
			}
		}
	}

	@include breakpoint(large up) {
		&.testimonial {
			.info {
				padding: 14rem 0;

				.testimonial-quote {
					h3 { 
						font-size: rem-calc(46px); 
						letter-spacing: rem-calc(1.5px);
					}
				}
				.testimonial-author { font-size: rem-calc(14px); }
			}
		}

		&.promotion {
			.info {
				padding: rem-calc(145px) 0;
			}
		}
	}
}
