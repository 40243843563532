 /* ==========================================================================

    Project
    ==========
    El Cosmico

    Author
    ==========

                                      ,8  ,8
                                      88  88
                                      88  88
    `8b      db      d8' ,adPPYYba,   88  88   ,adPPYba,   ,b,dPPYba,
     `8b    d88b    d8'  ""     `Y8   88  88  a8"     "8a  88P'    "8a
      `8b  d8'`8b  d8'   ,adPPPPP88   88  88  8b       d8  88       d8
       `8bd8'  `8bd8'    88,    ,88   88  88  "8a,   ,a8"  88b,   ,a8"
         YP      YP      `"8bbdP"Y8"  88  88   `"YbbdP"'   88`YbbdP"'
                                                           88
                                                           8"

 ========================================================================== */

/****
 *  Components
 */

@import
"components/fonts",
"components/mixins",
"components/svg",
"components/forms",
"components/subscribe";

/****
*  Global Styles
*/

.site-title {
  a {
    padding-top: percentage(35/238);
    display: block;
    width: 100%;
    max-width: 100%;
    height: 0;
    background-image: url('../images/el-cosmico-small.png');
    background: {
      image: url('../images/el-cosmico-small.svg');
      repeat: no-repeat;
      size: contain;
      position: center center;
    }
    font-size: 0;

    @include breakpoint(medium up) {
      max-width: none;
    }

    @include breakpoint(large up) {
      padding-top: percentage(56/238);
      background-image: url('../images/el-cosmico.png');
      background-image: url('../images/el-cosmico.svg');
    }
  }
}

.no-bullet {
  &:last-child {
      margin-bottom: 0;
  }
}

// Additional rules for theme

// Padding
$small-padding: rem-calc(20);
$large-padding: rem-calc(30);

// Mixins
@mixin cristobal-uppercase($letter-spacing: 2px) {
	text-transform: uppercase;
	letter-spacing: $letter-spacing;
}

// Global rules
.row-full-width { max-width: 100%; }

body {
  background: {
    image: url('../images/bg-pattern.png');
    color: $body-background;
  }

  main { 
    padding: 0 1rem; 
    overflow: hidden;
  }
}

#page { min-width: em-calc(320); }

.sr-only {
  position: absolute !important;
  margin: rem-calc(-1);
  padding: 0;
  height: rem-calc(1);
  width: rem-calc(1);
  border: 0;
  overflow: hidden;
  word-wrap: normal !important;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
}

@import
"mobile-menu",
"header",
"layout",
"modules",
"components",
"templates",
"footer";
