// CSS rules for Austin Motel's header
.header-carousel-wrapper {
	position: relative;
}

// PROMOTIONS
.header-promo-banner {
    color: $black;
    letter-spacing: .6px;
    background-color: rgba($tan-background-color, .86);
	border-top: 1px solid rgba(0,0,0,.025);
	border-bottom: 1px solid rgba(0,0,0,.05);
	
	span,a { 
		font-size: 1.15rem; 
		font-weight: bold;
	}

	span { text-transform: uppercase; }
}

// PROMO BANNER
.header-promo-banner {
	position: absolute;
	width: 100%;
	z-index: 29;

	padding: rem-calc(16 0 10);
	text-align: center;

	a { color: #d64626; }
}

// SITE HEADER
.site-header {
	position: relative;
	margin-bottom: rem-calc(8);
	min-height: rem-calc(87);

	@include breakpoint(xxlarge) {
		margin-bottom: rem-calc(12);
	}

	.home & {
		border-bottom: none;
	}

	.mobile-book-now {
		@include cristobal-uppercase;
		font-weight: $global-weight-bold;
		font-size: rem-calc(18);
		text-align: center;
		margin: ($small-padding * 0.85) $small-padding 0;
		padding-bottom: ($small-padding * 0.6);
		border-bottom: 1px solid $hairline-color;
		line-height: 1;

		@include breakpoint(medium up) {
			display: none;
		}

		a:link, a:visited {
			color: $nav-link-color;
		}

		a:hover, a:active {
			color: $nav-hover-color;
		}
	}

	// LOGO
	.site-branding {
		float: left;
		margin: 1.25rem 1.25rem 0 0;
		width: 56%;
		max-width: 238px;
		z-index: 115;

		.site-title {
			margin: 0;
			text-align: center;

			@include breakpoint(medium down) {
			    a { padding-top: 20%; }
			}
		}

		@include breakpoint(medium up) {
			float: none;
			width: rem-calc(340);
			max-width: none;
			margin: 0;
			position: absolute;
			top: 30%;
			left: 50%; // added in to compensate for missing "offers" nav item
			transform: translate(-50%, -50%);
		}

		@include breakpoint(medium only) {
			margin-top: rem-calc(10);
			width: rem-calc(308);
		}

		@include breakpoint(large) {
			margin-top: rem-calc(10);
			width: rem-calc(238);
		}

		@include breakpoint(xlarge up) { 
			margin-top: 0;
			top: 50%; 
		}

		@include breakpoint(xxlarge up) { 
			width: rem-calc(280);
		}
	}
	

	// NAVIGATION
	.main-navigation {
		// Mobile toggle positioning
		float: right;
		padding-right: 0;

		@include breakpoint(medium up) {
			margin: rem-calc(80) auto 0;
			width: 90%;
			float: none; // allow for desktop alignment
		}

		@include breakpoint(xlarge) {
			margin: rem-calc(33) auto 0;
			max-width: rem-calc(1292);
			width: rem-calc(1292);
		}

		@include breakpoint(xxlarge) {
			max-width: rem-calc(1350);
			width: rem-calc(1350);
		}

		// NAVIGATION WRAPPER
		.nav-wrapper {
			margin: 0 auto;
			
			a { 
				font-weight: $global-weight-bold; 
				border-bottom: 2px solid transparent;
				transition: color .1s ease-in-out, border .1s ease-in-out;
				
				&:link, &:visited { color: $nav-link-color; }
				
				&:hover, &:active, { 
					padding-bottom: .05rem; // added in for the texta typeface update
					border-bottom: 2px solid $nav-hover-color;
					color: $nav-hover-color; 
				}

				@include breakpoint(medium down) {
					text-align: center;

					a:link, a:visited { color: $nav-link-color; }
					a:hover, a:active { color: $nav-hover-color; }
				}
			}

			.current-page-ancestor {
				a {
					padding-bottom: .05rem; // added in for the texta typeface update

					border-bottom: 2px solid $nav-hover-color;
					color: $nav-hover-color; 

					@include breakpoint(medium down) {
						color: $nav-active-color;
					}
				}
			}
				
			> ul {
				margin: 0;
				padding: rem-calc(80) 0 0;
				@include cristobal-uppercase;
				font-size: rem-calc(16);
				display: none;

				@include breakpoint(medium up) {
					@include menu-simple;
					margin-top: rem-calc(14);
					margin-left: 0;
					padding-top: 0;
					display: block;
					z-index: 110;
					text-align: center;

					// Cancel overlay navigation - DO NOT REMOVE
					position: relative;
					width: auto;
					height: auto;
					background-color: transparent;
				}

				@include breakpoint(xxlarge) {
					margin-top: rem-calc(22);
				}

				> li {
					text-align: center;
					line-height: inherit;
					font-size: $global-font-size;

					&:nth-last-of-type(2) {
						margin-right: 0;
					}	

					@include breakpoint(medium down) {
						position: relative;
						float: none;
						margin: 0 rem-calc(13) rem-calc(3);
						display: inline-block;
						text-align: left;
					}

					@include breakpoint(large) { 
						margin: 0 rem-calc(13) rem-calc(3);
					}

					@include breakpoint(xlarge) {
						margin: {
							left: 0;
							right: rem-calc(15);
						}
					}

					@include breakpoint(xxlarge) {
						position: relative;
						float: left;
						margin: 0 rem-calc(13);
						display: block;
					}

					&.book-now-button { 
						margin: {
							left: 0;
							right: 0; 
							bottom: 0;
						}
						padding-bottom: 0.75rem;
						display: block;
						border-top: none;

						@include breakpoint(medium up) {
							padding-bottom: 0;
						} 
					}

					&:nth-child(2) { border-top: 0; }
				}

				> div { margin-top: 1.5rem; }
			}

			.nav-footer-links {
				margin: 1.15rem .5rem 1.5rem 1.5rem;
				text-align: center; 

				li { 
					margin-right: 1rem;
					display: inline; 

					&:last-child { margin-right: 0; }
				}

				span { margin-right: 1rem; }

				@include breakpoint(small only) {
					float: none;
					width: 255px;
					margin: {
						left: auto;
						right: auto;
						bottom: .5rem;
					}
					overflow: hidden;

					li { 
						display: inline-block;
						width: auto !important;
						&:nth-child(4){ margin-right: 0; } 
					}
				}
			}

			// WHEN MOBILE MENU IS OPEN
			&.is-open {
				ul {
					display: block;

					a { color: $white; }
					
					li { 
						margin-left: 0;
						width: 100%; 
						text-align: center;

						@include breakpoint(small only) {
							.nav-footer-links {
								float: none;
							}
						}
					}
				}

				// CLOSE BUTTON
				.menu-close-button { 
					margin: {
						top: 1.2rem;
						right: 1rem;
					}
					position: absolute;
					top: 0;
					right: 0;
					z-index: 126;
					padding: 1rem 1.5rem 0.8rem;
					display: block; 
					outline: none;
					background-color: transparent;
					font-size: rem-calc(17.5);
					color: $white;

					border: 1px solid $white;
					border-radius: 2px;
		
					.icon-menu-close { 
						position: relative;
						font-size: 1.15rem;
						right: -0.35rem;
						margin-right: 0;
						line-height: 0;
					}
				}
			}

			// MOBILE CLOSE BUTTON
			.menu-close-button {
				display: none;

				@include breakpoint(medium up) {
					display: none !important;
				}
			}

			@include breakpoint(medium) { width: 100%; }
			
			@include breakpoint(large) { 
				max-width: rem-calc(854);
				width: rem-calc(854);
			}

			@include breakpoint(xlarge) { 
				max-width: rem-calc(1292);
				width: rem-calc(1292);

				li:first-child { margin-left: -3.45rem; }

				// Spacing to allow for nav gap for center logo placement
				li:nth-child(3) { margin-right: rem-calc(292); }
			}
			
			@include breakpoint(xxlarge) { 
				max-width: rem-calc(1350);
				width: rem-calc(1350); 

				// Spacing to allow for nav gap for center logo placement
				//li:first-child { margin-left: rem-calc(171.5); }
				li:first-child { margin-left: rem-calc(157.5); }
				li:nth-child(3) { margin-right: rem-calc(320); }

				.top-level {
					height: rem-calc(50);
				}
			}

			// BOOK NOW BUTTON
			.book-now-button {
				display: inline-block;
				background-color: $nav-hover-color;

				a {
					margin-bottom: 0;
					color: $white;
					border-bottom: none;
					text-align: center;

					&:hover, 
					&:active,
					&:focus { color: $white; }

					@include breakpoint(small) {
						background: transparent;
						text-transform: uppercase;
						letter-spacing: 1px;
						color: $white;
					}
				}

				@include breakpoint(medium up) {
					display: inline;

					a {
						@include button();
						@include border-radius(2px);
						position: fixed;
						right: rem-calc(50);
						bottom: rem-calc(12);
						padding: 1.75rem 0 0 .15rem; // added in for the texta font update
						height: 80px;
						width: 80px;
						background-color: $nav-hover-color;
						border-radius: 50%;
						box-shadow: 0px 1.5px 7px rgba(0,0,0,.1);
						z-index: 50;
						font-size: rem-calc(14); 
						line-height: 1.25rem;
					}
				}
			}
		}


		// MOBILE MENU TOGGLE BUTTON
		.menu-toggle {
			float: right;
			margin: rem-calc(17) 0 0;
			padding: rem-calc(12.5 10 14 16);

			background: $nav-link-color;
			outline: none;
			@include border-radius(2px);
			@include cristobal-uppercase;
			color: $white;
			font-size: rem-calc(14);
			font-weight: $global-weight-bold;
			line-height: 1;

			right: 0;
			position: absolute;

			
			@include breakpoint(medium up) {
				position: relative;
				display: none;
			}

			.icon-menu { 
			    position: relative;
				top: 2px;
				font-size: 1.45rem;
		    	line-height: 0;
		  	}
		}
	}
}

// SECONDARY HEADER
.secondary-header-wrapper {
	position: relative; 
	margin-bottom: 3.75rem;

	@include breakpoint(medium down) {
		margin-bottom: 0;
	}

	&.single-blog-post {
		.header-carousel { 
			height: 55vh; 

			.img-fill { height: 55vh; }
		}
	}

	// SUB NAVIGATION
	.sub-nav-relative {
		position: relative;

		.sub-navigation { margin-bottom: 0;}
	}

	.sub-navigation {
		padding: .95rem 0 .75rem;
		display: inline-block;
		width: 100%;

		@include breakpoint(medium up) {
			font-size: rem-calc(16); 
		}
		
		@include breakpoint(large up) {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			background-color: rgba($tan-background-color, .72);
			border-top: 1px solid rgba(0,0,0,.05);
			border-bottom: 1px solid rgba(0,0,0,.05);

			a {
				border-bottom: 4px solid transparent;

				&:hover, &:active, { 
					border-bottom: 4px solid $nav-hover-color;
				}
			}
		}

		@include breakpoint(medium only) { 
			padding-bottom: 0;
			border-bottom: none;
		}

		ul { 
			@include menu-simple; 
			margin: 0;
			padding: 0;
			text-align: center;

			li {
				margin: 0 .65rem .5rem;

				@include breakpoint(medium) { margin-bottom: 1.25rem; }
				@include breakpoint(large) { margin-bottom: 0; }

				a { 
					padding-bottom: .9rem;
					letter-spacing: .5px;

					color: $black;
					font-weight: $global-weight-bold; 
					transition: color .1s ease-in-out, border .1s ease-in-out;
					
					&:hover, &:active, { 
						color: $nav-hover-color; 
					}
				}
			}

			@include breakpoint(small only) {
				padding-bottom: 1.5rem !important;
			}

			@include breakpoint(medium down) {
				border-bottom: 1px solid rgba(0,0,0,.05);

				li {
					a { padding-bottom: .75rem; }
				}
			}
		}

		.current_page_item {
			a {
				border-bottom: 4px solid $nav-hover-color;
				color: $nav-hover-color; 

				@include breakpoint(medium down) {
					color: $nav-active-color;
				}
			}
		}
	}

	&.block-sub-nav {
		.sub-navigation {
			padding: .9rem 0 .85rem;

			@include breakpoint(large up) {
				border-top: 1px solid $tan-border-color;
				border-bottom: 1px solid $tan-border-color;
				position: relative;
				background-color: transparent;

				a {
					border-bottom: 4px solid transparent;

					&.active,
					&:hover, &:active, { 
						border-bottom: 4px solid $nav-hover-color;
					}
				}
			}
		}
	}
}