// INSTAGRAM FEED
.instagram-feed {
	margin-top: 4rem;
	overflow: hidden;

	.item {
		padding-left: .25rem;
		padding-right: .25rem;

	}

	h2 { text-align: center; }
	a { 
		color: $nav-hover-color; 
		
		&:hover { color: $nav-active-color; }
	}
}