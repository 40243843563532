// CSS rules for the neighborhood page template

// TO-DO MAP
.location-map {
	height: auto;
	color: $white;

	@include breakpoint(medium up) {
		margin: {
			top: rem-calc(68px);
			bottom: 0;
		}
	}

	#map {
		width: 100%;
		height: 65vh;
	}

	&.contact-map {
		height: 55vh;

		#map {
			height: 100%;
		}

		@include breakpoint(medium up) {
			margin-top: rem-calc(40px);
		}
	}

	.todo-map-filter {
		padding: 1rem;
		width: 100%;
		background: rgba(0,0,0,.75);
		font-weight: $global-weight-bold;

		@include breakpoint(medium up) {
			padding: 1rem 3rem 0.5rem;
		}

		@include breakpoint(large up) {
			position: absolute;
			bottom: 0;
			left: 0;
		}

		ul {
			margin: 0;

			li, a { color: $white; }

			.filter-show-all {
				background: rgba(0,0,0,1);
				padding: 1rem 1rem .5rem;
				border-radius: 4px;
			}

			li {
				margin-right: 1.5rem;
				display: inline-block;

				&:last-of-type { margin-right: 0; }

				a {
					font: {
						size: rem-calc(15);
						weight: $global-weight-normal;
					}
					letter-spacing: rem-calc(1);


					img {
						position: relative;
						height: rem-calc(60px);
						top: .25rem;
						z-index: 2;
					}
				}
			}
		}
	}
}

// INFOBOX
.infoBox {
	position: relative;
	padding: 1rem;
	background: $black;
	border-radius: 4px;
	text-align: left !important;

	h5, p {
		font-family: $body-font-family;
	}

	> h5 {
		margin-top: 0;
		margin-bottom: 1rem;
		color: #FFF;
		font: {
			size: rem-calc(16);
			weight: $global-weight-bold;
		}
		text-transform: inherit;
		letter-spacing: 1px;
	}

	p {
		font: {
			size: rem-calc(14);
			weight: $global-weight-normal;
		}
		line-height: rem-calc(22.75);
		letter-spacing: 1px;
	}

	p:last-child {
		margin-bottom: 0;
	}

	a {
		display: inline-block;
		margin-top: 1rem;
		color: $nav-hover-color;

		&:hover {
			color: $nav-active-color;
		}
	}

	&::after {
	    content: '';
	    position: absolute;
	    top: 100%;
	    width: 0;
	    height: 0;
	    border-left: 20px solid transparent;
	    border-right: 20px solid transparent;
	    border-top: 20px solid $black;
	    left: 50%;
	    margin-left: -20px;
	}

	img {
		margin: 0 -5px 0 0 !important;
	}
}
