@mixin icon-font {
	font-family: 'fontello';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// Vertical padding
@mixin vert-padding($spacing) {
	padding: {
		top:$spacing;
		bottom:$spacing;
	}
}

// Horizontal padding
@mixin horiz-padding($spacing) {
	padding: {
		left:$spacing;
		right:$spacing;
	}
}

// Vertical margin
@mixin vert-margin($spacing) {
	margin: {
		top:$spacing;
		bottom:$spacing;
	}
}

// Horizontal margin
@mixin horiz-margin($spacing) {
	margin: {
		left:$spacing;
		right:$spacing;
	}
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}